import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeleteDialogStatus } from '@qbitartifacts/qbit-kit-ng';
import { BillsService } from 'src/app/services/CRUD/recogeme/bills.service';

export interface ConfirmationDialogData {
  title: string;
  subtitle: string;
  description: string;
  confirmButtonText: string;
}

@Component({
  selector: 'caste-delete-confirmation',
  templateUrl: './calculate-bill.component.html',
  styleUrls: ['./calculate-bill.component.scss'],
})
export class CalculateBillComponent {
  public title = 'CALCULATED_PRICE';
  public subtitle = 'DELETE_ITEM_CONFIRM';
  public confirmButtonText = 'BACK';
  public isLoading = true;

  constructor(
    public dialogRef: MatDialogRef<CalculateBillComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: string },
    public bills$: BillsService,
  ) {
    this.setIfPresent(data, 'title');

    this.bills$.CalculateBill(data.id).then(amount => {
        this['subtitle'] = amount;
        this['isLoading'] = false;
    });
  }

  private setIfPresent(data: any, key: string) {
    if (data[key] !== undefined) {
      this[key] = data[key];
    }
  }

  close(status: DeleteDialogStatus = DeleteDialogStatus.CANCEL) {
    this.dialogRef.close(status);
  }

}
