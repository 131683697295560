import { ImagePreviewData } from './../dialogs/image-preview/image-preview.component';
import { AddDocumentComponent } from 'src/app/dialogs/add-document/add-document.component';
import { IRate } from '../core/entities/rate';
import { CreateRateComponent } from './../dialogs/create-rate/create-rate.component';
import {
  CreateBillComponent,
  CreateBillDialogData,
} from './../dialogs/create-bill/create-bill.component';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeleteConfirmationComponent } from '../dialogs/delete-confirmation/delete-confirmation.component';
import { CalculateBillComponent } from "../dialogs/calculate-bill/calculate-bill.component"
import { EditBillComponent, EditBillData } from '../dialogs/edit-bill/edit-bill.component';
import { IBill } from '../core/entities/bill';
import { EditRateComponent } from '../dialogs/edit-rate/edit-rate.component';
import { IRecord } from '../core/entities/record';
import { ImagePreviewComponent } from '../dialogs/image-preview/image-preview.component';
import {
  CreateConfigComponent,
  CreateConfigData,
} from '../dialogs/create-config/create-config.component';
import { IConfig } from '../core/entities/config';
import { EditConfigComponent } from '../dialogs/edit-config/edit-config.component';
import { UploadLogoComponent } from '../dialogs/upload-logo/upload-logo.component';

@Injectable({
  providedIn: 'root',
})
export class DialogsService {
  constructor(public dialog: MatDialog) {}

  /* istanbul ignore next */
  open<T = any>(component, data = {}, options: Partial<MatDialogConfig> = {}) {
    return this.dialog.open<T>(component, {
      ...options,
      data,
    });
  }

  /* istanbul ignore next */
  openConfirmDelete(options?: Partial<MatDialogConfig>) {
    return this.open(
      DeleteConfirmationComponent,
      {},
      { ...options, width: '400px' }
    );
  }

  openCreateBill(
    data?: CreateBillDialogData,
    options?: Partial<MatDialogConfig>
  ) {
    return this.open(CreateBillComponent, data, { ...options, width: '400px' });
  }

  openCreateConfig(data: CreateConfigData, options?: Partial<MatDialogConfig>) {
    return this.open(CreateConfigComponent, data, {
      ...options,
      width: '500px',
    });
  }

  openEditConfig(data: IConfig, options?: Partial<MatDialogConfig>) {
    return this.open(EditConfigComponent, data, {
      ...options,
      width: '500px',
    });
  }

  openEditBill(data: EditBillData, options?: Partial<MatDialogConfig>) {
    return this.open(EditBillComponent, data, { ...options, width: '50%' });
  }

  openCalculateBillDelete(bill: IBill, options?: Partial<MatDialogConfig>) {
    return this.open(
      CalculateBillComponent,
      { id: bill.id },
      {
        ...options,
        width: '400px'
      }
    );
  }

  openCreateRate(options?: Partial<MatDialogConfig>) {
    return this.open(CreateRateComponent, {}, { ...options, width: '50%' });
  }

  openEditRate(data: IRate, options?: Partial<MatDialogConfig>) {
    return this.open(EditRateComponent, data, { ...options, width: '50%' });
  }

  openImagePreview(data: ImagePreviewData) {
    return this.open(ImagePreviewComponent, data);
  }

  openAddDocument(record: IRecord, options?: Partial<MatDialogConfig>) {
    return this.open(
      AddDocumentComponent,
      { record },
      { ...options, width: '500px' }
    );
  }

  openAddLogo(options?: Partial<MatDialogConfig>) {
    return this.open(UploadLogoComponent, {}, options);
  }
}
