import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from 'src/app/services/auth.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IRecord, Record } from 'src/app/core/entities/record';
import { CrudBaseService } from 'src/app/core/base/crud.base.service';
import {
  CasteAccountsService,
  CasteUsersService,
} from '@qbitartifacts/caste-client-ng';
import { map } from 'rxjs/internal/operators/map';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RecordsService extends CrudBaseService<IRecord> {

  // PROPERTIES
  public queryParams;

  // METHODS

  // Constructor
  constructor(
    http: HttpClient,
    auth: AuthService,
    public users$: CasteUsersService,
    public accounts$: CasteAccountsService
  ) {
    super(
      {
        endpoint: 'records',
      },
      http,
      auth
    );
  }

  // Get
  public getOne(id: string, userType?: string): Observable<IRecord> {
    return super.getOne(id, userType).pipe(
      map((el) => {
        el.driver = this.users$.getOne(el.user_id, 'user');
        return el;
      })
    );
  }

  // List
  public listAll(
    params?: { [key: string]: string },
    userType?: string
  ): Observable<IRecord[]> {
    const path: string = this.createPathFromParts(
      userType,
      `/${this.opts.endpoint}`
    );

    this.queryParams = params;
    
    return this.get<IRecord[]>(path, params).pipe(
      map((resp: any) => {
        resp.data = resp.data.map((el) => {
          el.user = this.users$.getOne(el.user_id, 'user');
          el.account = this.accounts$.getOne(el.account_id, 'admin');
          el.started_at = new Date(el.started_at).getTime();
          return el;
        });
        return resp;
      })
    );
  }

  // Report AP
  public reportAP(
      userType?: string
  ) {
      const path: string = this.createPathFromParts(
          userType,
          `/Reports/ReportAP`
      );

      this.get<any>(path, this.queryParams).subscribe(data => {
          
          const exelBlob = this.dataURItoBlob(data.excel);
          const url = window.URL.createObjectURL(exelBlob);
          
          window.open(url);

      })
  }

  // Report EN
  public reportEN(
      userType?: string
  ) {
      const path: string = this.createPathFromParts(
          userType,
          `/Reports/ReportEN`
      );

      this.get<any>(path, this.queryParams).subscribe(data => {
          
          const exelBlob = this.dataURItoBlob(data.excel);
          const url = window.URL.createObjectURL(exelBlob);
          
          window.open(url);

      })
  }

  // Report EN Bill
  public reportENBill(
      userType?: string
  ) {
      const path: string = this.createPathFromParts(
          userType,
          `/Reports/ReportENBill`
      );

      this.get<any>(path, this.queryParams).subscribe(data => {
          
          const exelBlob = this.dataURItoBlob(data.excel);
          const url = window.URL.createObjectURL(exelBlob);
          
          window.open(url);

      })
  }

  // Map fro json
  public mapItem(item: IRecord) {
    return new Record().fromJson(item);
  }

  // Delete blob
  private dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });    
    
    return blob;
  }

  // Record account edit
  public recordAccountEdit(recordAccountEdit: { account: string; record: string }): Observable<boolean> {
    
    const path: string = this.createPathFromParts(
      `Records/RecordAccount/`,
      ''
    );

    return this.post<boolean>(path, recordAccountEdit).pipe(
      map((resp) => {
        return resp;
      })
    );
  }
}
