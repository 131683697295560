import { DisplacementTypes, RecordStatuses } from './../../../entities/record';
import { Component, OnInit } from '@angular/core';
import { IRecord } from 'src/app/core/entities/record';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { DialogsService } from 'src/app/services/dialogs.service';
import { RecordsService } from 'src/app/services/CRUD/recogeme/records.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  QEventsService,
  QSnackBar,
  QTableBase,
  QTableListHeaderOptions,
} from '@qbitartifacts/qbit-kit-ng';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { EditRecordComponent } from 'src/app/dialogs/edit-record/edit-record.component';
import { EditRecordAccountComponent } from 'src/app/dialogs/edit-record-account/edit-record-account.component';
import { RecogemeTablePageBase } from 'src/app/core/base/recogeme.table.base';
import { TranslateService } from '@ngx-translate/core';
import { BillStatuses } from 'src/app/core/entities/bill';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-records-list',
  templateUrl: './records-list.component.html',
  styleUrls: ['./records-list.component.scss'],
})
export class RecordsListComponent
  extends RecogemeTablePageBase<IRecord>
  implements OnInit
{
  public displayedColumns: string[] = [
    'internal_id',
    'license_plate',
    'displacement_type',
    'user',
    'status',
    'bill_status',
    'brand_make',
    'started_at',
    'options',
  ];
  public query: string;
  public isLoading = false;
  public filterByOwner = true;
  public environment = environment;
  public tableOptions: QTableListHeaderOptions = {
    showLoading: true,
    showBreadcrumbs: true,
  };
  public hiddenFilters = ['id', 'updated_at', 'created_at'];
  public autocompleteData = {
    'bill.status': BillStatuses.map(
      RecogemeTablePageBase.createAutocompleteItem
    ),
    'filter.has.bill': [
      { display: 'HAS_BILL', value: true },
      { display: 'HAS_NO_BILL', value: false },
    ],
    status: RecordStatuses.map(RecogemeTablePageBase.createAutocompleteItem),
    displacement_type: DisplacementTypes.map(
      RecogemeTablePageBase.createAutocompleteItem
    ),
  };

  constructor(
    public snackbar: QSnackBar,
    public dialogs: DialogsService,
    public events: QEventsService,
    public app: AppService,
    public records$: RecordsService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    public user$: UserService
  ) {
    super(snackbar, events, router, activatedRoute);
    this.initialSearch = true;
    this.autoRefresh = false;
  }

  public getSearchObservable(queryParams: {
    [key: string]: string;
  }): Observable<any> {
    return this.records$.listAll(queryParams);
  }

  public getRemoveItemObservable(id: string): Observable<any> {
    return this.records$.remove(id);
  }

  public getRemoveItemDialog(id: string): MatDialogRef<any, any> {
    return this.dialogs.openConfirmDelete();
  }

  public openEditDialog(item) {
    this.dialogs
      .open(EditRecordComponent, item)
      .afterClosed()
      .subscribe((resp) => {
        if (resp) this.onSearch(this.searchParams);
      });
  }

  addBill(item) {
    this.dialogs
        .openCreateBill({
            record: item,
            disableRecordSelector: true,
        })
        .afterClosed()
        .subscribe((resp) => {
            if (resp) {
                this.onSearch(this.searchParams);
            }
        });
  }

  public editRecordAccount(item) {
    this.dialogs
      .open(EditRecordAccountComponent, item)
      .afterClosed()
      .subscribe((resp) => {
        if (resp) this.onSearch(this.searchParams);
      });
  }

  public getOwner(): string {
      return this.app.getOwner();
  }

  public reportAP () {
      return this.records$.reportAP();
  }

  public reportEN () {
      return this.records$.reportEN();
  }

  public reportENBill () {
      return this.records$.reportENBill();
  }
}
