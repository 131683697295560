<qbit-dialog-header title="{{'CHANGE_RECORD_ACCOUNT' | translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper" style="min-width: 400px;;">
    <form class="padd-20" [formGroup]="form" (ngSubmit)="edit()">
        <div class="row">
            <div class="col-lg-12">
                <h4 class="title-lg marg-top-10">
                    {{'CHANGE_RECORD_ACCOUNT' | translate}}
                </h4>
                <p class="text-mutted marg-bottom-15">{{'CHANGE_RECORD_ACCOUNT' | translate}}</p>

                <div class="row">

                    <div class="col-12">
                        <mat-form-field>
                            <mat-label>{{'CHANGE_RECORD_SELECT' | translate}}</mat-label>
                            <mat-select formControlName="account">
                                <mat-option *ngFor="let accountItem of accounts" [value]="accountItem.id">
                                    {{ accountItem.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </div>

                <div class="flex-row end">
                    <qbit-loadable-button [disabled]="form.invalid || isLoading || !form.dirty" [loading]="isLoading">
                        {{(isLoading ? 'SAVING': 'SAVE') |translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>