<mat-card class="no-padding">
    <qbit-table-header [options]="tableOptions" title="RECORDS" [isLoading]="isLoading">
        <button class="marg-left-10" mat-raised-button color="primary" (click)="reportAP()">
            {{'APREPORT' | translate}}
        </button>

        <button class="marg-left-10" mat-raised-button color="primary" (click)="reportEN()">
            {{'ENREPORT' | translate}}
        </button>

        <button class="marg-left-10" mat-raised-button color="primary" (click)="reportENBill()">
            {{'ENBILLREPORT' | translate}}
        </button>
        
        <button mat-icon-button (click)="onSearch(searchParams)">
            <mat-icon>refresh</mat-icon>
        </button>
    </qbit-table-header>

    <qbit-table-filters-addable *ngIf="searchMapping && searchMapping.length" [searchMapping]="searchMapping"
        [autocomplete]="autocompleteData" (filtersChanged)="searchParamsChanged($event)" [filters]="searchParams"
        [hiddenFilters]="hiddenFilters">
    </qbit-table-filters-addable>

    <table mat-table [dataSource]="dataSource" matSort class="hoverable" (matSortChange)="sortChanged($event)"
        [matSortDirection]="sortDir" [matSortActive]="sortId" [trackBy]="trackById">

        <ng-container matColumnDef="internal_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'INTERNAL_ID'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <code class="text-small">{{row.internal_id}}</code>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'STATUS'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <span class="status-{{row.status}}">
                    {{row.status|translate}}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="bill_status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'BILL_STATUS'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="row.displacement_type != 'AP'">
                    <span *ngIf="row.bill" class="status-{{row.bill.status}}">
                        {{row.bill.status|translate}}
                    </span>

                    <i *ngIf="!row.bill && !row.charged_on_route" class="text-mutted">
                        {{'NO_BILL'|translate}}
                    </i>

                    <!-- Cobrado en ruta -->
                    <i matTooltip="{{'CHARGE_ON_ROUTE_INFO'|translate}}" *ngIf="!row.bill && row.charged_on_route"
                        class="text-mutted col-warning">
                        {{'CHARGED_ON_ROUTE'|translate}}
                    </i>
                </ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="started_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'STARTED_AT'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <i
                    matTooltip="Comienzo: {{row.started_at | date: 'medium'}} | Finalizado: {{row.finished_at  | date: 'medium'}}">
                    {{row.started_at | date: 'medium'}}
                </i>
            </td>
        </ng-container>

        <ng-container matColumnDef="displacement_type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'DISPLACEMENT'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <span
                    [matTooltip]="(row.inmobilized ? 'INMOBILIZED': row.in_scrapping ? 'IN_SCRAPPING' :row.charged_on_route ? 'CHARGED_ON_ROUTE': '')|translate"
                    [ngClass]="{
                    'col-error': (row.displacement_type === 'EN' && row.inmobilized),
                    'col-warning': (row.displacement_type === 'EN' && row.charged_on_route),
                    'col-blue': (row.displacement_type === 'EN' && row.in_scrapping) 
                }">
                    {{row.displacement_type}}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="license_plate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'VEHICLE'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <div>
                    <app-vehicle-chip tooltip="" *ngIf="row && row.vehicle" [vehicle]="row.vehicle">
                    </app-vehicle-chip>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="user_id"> {{'USER'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <app-caste-link *ngIf="row.user | async as driver" [user]="driver" [env]="environment">
                    <qbit-chip label="{{driver ? driver.username: '---'}}"></qbit-chip>
                </app-caste-link>
            </td>
        </ng-container>

        <ng-container matColumnDef="brand_make">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'BRAND_MAKE'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                {{row.vehicle ? row.vehicle.brand: '---'}} {{row.vehicle ? row.vehicle.make: '---'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="updated_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'UPDATED'|translate}} </th>
            <td mat-cell *matCellDef="let row"> {{row.updated_at | date: 'medium'}} </td>
        </ng-container>

        <ng-container matColumnDef="options" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <button *ngIf="user$.isAdmin() || user$.isSuperadmin()" mat-icon-button [matMenuTriggerFor]="menu"
                    aria-label="Item options menu" (click)="$event.preventDefault(); $event.stopPropagation()">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="openEditDialog(row)">
                        <mat-icon class="mat-icon material-icons mat-icon-small">edit</mat-icon>
                        <span>{{'EDIT'|translate}}</span>
                    </button>

                    <button *ngIf="row.bill" mat-menu-item routerLink="/bills" [queryParams]="{ 'record.id': row.id }">
                        <mat-icon class="mat-icon material-icons mat-icon-small">receipt_long</mat-icon>
                        <span>{{'BILLS'|translate}}</span>
                    </button>

                    <button *ngIf="!row.bill" mat-menu-item (click)="addBill(row)">
                        <mat-icon class="mat-icon material-icons mat-icon-small">receipt_long</mat-icon>
                        <span>{{'ADD_BILL'|translate}}</span>
                    </button>

                    <button mat-menu-item class="col-error" (click)="openRemoveConfirmation(row.id)">
                        <mat-icon class="mat-icon material-icons mat-icon-small">delete</mat-icon>
                        <span>{{'REMOVE'|translate}}</span>
                    </button>

                    <button *ngIf="!row.bill" mat-menu-item (click)="editRecordAccount(row)">
                        <mat-icon class="mat-icon material-icons mat-icon-small">receipt</mat-icon>
                        <span>{{'CHANGE_RECORD_ACCOUNT'|translate}}</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <tr class="tr-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" routerLink="/records/{{row.id}}"
            [ngClass]="{ 'bg-warning': !row.bill && row.charged_on_route }">
        </tr>
    </table>

    <qbit-list-loading *ngIf="isLoading && !hasData"></qbit-list-loading>

    <div class="list-no-items" *ngIf="!hasData && !isLoading">
        <p class="text-light">{{'NO_ITEMS'|translate}}</p>
    </div>

    <mat-paginator [length]="totalItems" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageChanged($event)"></mat-paginator>
</mat-card>