import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';
import { IdleNotificationComponent } from './idle-notification/idle.dia';
import { ComponentsModule } from '../core/components/components.module';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { EditRecordComponent } from './edit-record/edit-record.component';
import { EditRecordAccountComponent } from './edit-record-account/edit-record-account.component';
import { EditVehicleComponent } from './edit-vehicle/edit-vehicle.component';
import { CreateBillComponent } from './create-bill/create-bill.component';
import { CreateRateComponent } from './create-rate/create-rate.component';
import { EditBillComponent } from './edit-bill/edit-bill.component';
import { CalculateBillComponent } from './calculate-bill/calculate-bill.component';
import { EditRateComponent } from './edit-rate/edit-rate.component';
import { AddDocumentComponent } from './add-document/add-document.component';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { CreateConfigComponent } from './create-config/create-config.component';
import { EditConfigComponent } from './edit-config/edit-config.component';
import { UploadLogoComponent } from './upload-logo/upload-logo.component';

const dialogs = [
  IdleNotificationComponent,
  DeleteConfirmationComponent,
  EditRecordComponent,
  EditRecordAccountComponent,
  EditVehicleComponent,
  CreateBillComponent,
  CalculateBillComponent,
  CreateRateComponent,
  CreateConfigComponent,
  EditBillComponent,
  EditRateComponent,
  EditConfigComponent,
  AddDocumentComponent,
  ImagePreviewComponent,
  UploadLogoComponent,
];

@NgModule({
  declarations: dialogs,
  exports: dialogs,
  entryComponents: dialogs,
  imports: [SharedModule, ComponentsModule],
})
export class DialogsModule {}
