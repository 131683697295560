import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadableComponent } from 'src/app/core/base/loadable.page';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';
import { RecordsService } from 'src/app/services/CRUD/recogeme/records.service';
import { Record } from 'src/app/core/entities/record';
import { CasteUserService } from '@qbitartifacts/caste-client-ng';

@Component({
  selector: 'app-edit-account-record',
  templateUrl: './edit-record-account.component.html',
})
export class EditRecordAccountComponent implements OnInit, LoadableComponent {

  // PROPERTIES
  public form: FormGroup;
  public isLoading: boolean;
  public accounts = this.userS.user.accounts;
  public account = this.userS.selectedAccount.id;

  // METHODS

  // Constructor
  constructor(
    public dialogRef: MatDialogRef<EditRecordAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Record,
    public snackbar: QSnackBar,
    private formBuilder: FormBuilder,
    public recordS: RecordsService,
    public userS: CasteUserService
  ) {
  }

  // Initialize
  ngOnInit() {

    this.form = this.formBuilder.group({
      account: [this.account, Validators.required]
    });
  }

  // Edit
  edit() {
    if (!this.form.dirty || this.isLoading) return;

    this.setIsLoading(true);

    const data = {
      account: this.form.value.account,
      record: this.data.id
    };

    this.recordS.recordAccountEdit(data).subscribe(
      (resp) => {
        console.log(resp);
        this.snackbar.open('Expediente editado');
        this.close(true);
        this.setIsLoading(false);
      },
      (err) => {
        console.log(err);
        this.setIsLoading(false);
        this.snackbar.open(err.message);
      }
    );
  }

  // Close form
  close(val = false) {
    this.dialogRef.close(val);
  }

  // PRIVATE

  // Get account selected
  get displacement_type() {
    return this.form.get('account');
  }

  // Set loading
  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

}
