<qbit-dialog-header title="{{title | translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <div class="padd-20">
        <p *ngIf="!isLoading" class="subhead no-margin">
            {{subtitle}}
        </p>
        <div *ngIf="isLoading">
            <mat-spinner role="progressbar" mode="indeterminate" tabindex="-1" diameter="20" class="mat-spinner mat-progress-spinner white-spinner mat-primary mat-progress-spinner-indeterminate-animation ng-star-inserted" style="width: 20px; height: 20px;"><svg preserveAspectRatio="xMidYMid meet" focusable="false" aria-hidden="true" style="width: 20px; height: 20px;" viewBox="0 0 12 12"><circle cx="50%" cy="50%" style="animation-name: mat-progress-spinner-stroke-rotate-20; stroke-dasharray: 31.4159px; stroke-width: 10%;" r="5" class="ng-star-inserted"></circle><!----><!----></svg></mat-spinner>
        </div>
    </div>
</div>